export default (date_str="") => {
  try {
    const fecha = new Date(date_str);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    const hours = fecha.getHours();
    const minutes = fecha.getMinutes();
    const seconds = fecha.getSeconds();
  
    const fechaFormateada = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      
    return fechaFormateada;
  } catch (error) {
    console.log('Error: fechaFormateada', error, 'date_str', date_str); // Output: "2023-05-11 17:50:01"
    return date_str;
  }
}