<template>
  <div class="w-full">
    
    <TotalComplaints
      class="ml-0"
      :total="count"
      title="Total"
      body="registros para el reporte"
    />
    <div style="margin-bottom: 16px">
      <RatesFilter @notify-filters="generateReport" ref="filter_search" />
    </div>
    <div v-if="data.length > 0">
      <div v-if="!loading">
        <div
          class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white rates"
        >
          <div class="flex justify-between align-center p-3">
            <h3 class="font-bold sm:text-xl text-base text-left mt-3 ml-3">
              Reporte de tarifas <br />
              <span>{{
                actualQuery.filter_model.fecha_reporte.date_from
              }}</span>
            </h3>
            <a-menu mode="horizontal">
              <a-sub-menu>
                <a-button
                  slot="title"
                  type="primary"
                  style="margin-bottom: 16px"
                >
                  <a-icon type="menu-fold" />
                </a-button>
                <a-menu-item>
                  <vue-excel-xlsx
                    :loading="loadingReport"
                    :data="data"
                    :columns="columnsExcel"
                    file-name="Reporte-tarifas"
                    sheetName="Listado"
                  >
                    <span class="mx-2"
                      ><a-icon type="download" /> Descargar vista actual
                      XLSX</span
                    >
                  </vue-excel-xlsx></a-menu-item
                >
                <a-menu-item @click="onGetAllReport(false)">
                  <vue-excel-xlsx
                    :async="true"
                    :loading="loadingReport"
                    :data="json_data"
                    :columns="columnsExcel"
                    file-name="Reporte-tarifas(Completo)"
                    sheetName="Listado"
                  >
                    <span class="mx-2"
                      ><a-icon type="download" />Descargar reporte completo
                      XLSX</span
                    >
                  </vue-excel-xlsx>
                </a-menu-item>
                <a-menu-item @click="onGetAllReport(true)">
                  <vue-excel-xlsx
                    :async="true"
                    :loading="loadingReport"
                    :data="json_csv"
                    :columns="columnsExcel"
                    file-type="csv"
                    file-name="Reporte-tarifas(Completo)"
                    sheetName="Listado"
                  >
                    <span class="mx-2"
                      ><a-icon type="download" /> Descargar reporte completo
                      CSV</span
                    >
                  </vue-excel-xlsx></a-menu-item
                >
              </a-sub-menu>
            </a-menu>
          </div>
          <a-table
            :columns="columns"
            rowKey="id"
            :data-source="data"
            :pagination="false"
            class="mx-6 border border-gray-300 rounded-xl mb-6"
            :scroll="{ x: '1220px', y: 720 }"
          >
          </a-table>
          <div class="my-4 flex justify-end">
            <a-pagination
              v-model="page"
              :page-size="pageSize"
              :page-size-options="pageSizeOptions"
              :total="count"
              show-size-changer
              size="large"
              @showSizeChange="onShowSizeChange"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="loading"
        class="absolute w-full justify-center top-1/3 left-0"
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 64px"
            spin
          />
        </a-spin>
      </div>
    </div>
    <div v-else class="text-gray-600 text-2xl mt-6"><a-table>
      <div class="py-12">
        <b class="text-center text-4xl mx-auto text-gray-400">No hay datos disponibles</b>
      </div>
    </a-table></div>
  </div>
</template>
<script>
import capitalize from "@/utils/checkForms.js";
import formatDate from "@/utils/formatDate.js";
import RatesFilter from "@/components/Dashboard/Rates/RatesFilter.vue";
import moment from "moment";
import TotalComplaints from "@/components/Dashboard/Complaints/TotalComplaints.vue";
export default {
  components: {
    RatesFilter,
    TotalComplaints,
  },
  data() {
    return {
      data: [],
      columns: [],
      columnsExcel: [],
      json_csv: [],
      json_data: [],
      loading: false,
      loadingReport: false,
      current: null,
      showModal: false,
      page: 1,
      actualQuery: {},
      pageSize: 50,
      count: 0,
      search: null,
      filterSet: {},
      pageSizeDownload: 10000,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 50;
    }
    this.query = `?page=${this.page}&page_size=${this.pageSize}&`;
  },

  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router
          .replace({ query: { page: this.page } })
          .catch((err) => err);
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router
          .replace({
            query: { page: this.page, pageSize: this.pageSize },
          })
          .catch((err) => err);
      }
    },
    "$route.query.page": function () {
      this.generateReport();
    },
  },
  methods: {
    async generateReport(ev = this.actualQuery) {
      if(ev == null){
        console.log('campo null')
        return
      }
      
      this.actualQuery = ev;
      this.loading = true;
      this.generateColumns();
      if (this.columns.length == 0) {
        this.showToast("info", "No hay filtros aplicados");
        return;
      }
      this.loadingReport = true;
      this.actualQuery = ev;
      //this.getDataToPrint();
      this.showToast("info", "Generando reporte...");
      const bodyComplaint = {
        format: this.actualQuery.format.id,
        filter_model: this.actualQuery?.filter_model,
        limit: this.pageSize,
        offset: this.page - 1,
        append_columns: this.actualQuery?.append_columns
      };
      let { error, data } = await this.$api.generateReport(bodyComplaint);
      if (data) {
        this.data = data.results.map((item) => {
          return {
            ...item,
            formato: this.actualQuery.format.format,
            fecha_reporte: formatDate(item.fecha_reporte),
          };
        });
        this.count = data.count;
        if (this.count < 1) {
          this.showToast("info", "No se encontraron datos");
        }
        this.loadingReport = false;
        this.loading = false;
      } else if (error) {
        this.loading = false;
        this.showToast(
          "error",
          "Ocurió un error generando el reporte. Intente nuevamente."
        );
      }
    },
    generateColumns() {
      this.columns = [];
      this.columnsExcel = [];
      this.appliedDefaultCols();
      let keys_columns = Object.keys(this.actualQuery?.filter_model);
      let append_columns = this.actualQuery?.append_columns;
      let unique_columns = [...new Set([...keys_columns, ...append_columns])];

      Object.values(this.actualQuery.filterSet).forEach((el) => {
        unique_columns.forEach((filter) => {
          if (filter == el.name) {
            const existsInColumns = this.columns.some(column => column.key === filter);
            if (!existsInColumns) {
              this.columns.push({
                title: capitalize(el.description),
                dataIndex: filter,
                key: filter,
                width: 200,
              });
              // Excel
              this.columnsExcel.push({ label: el.description, field: filter });
            }
          }
        });
      });

      
    },
    appliedDefaultCols() {
      if (this.actualQuery.defaultCols.fecha_reporte) {
        this.columns.push({
          title: "Fecha",
          dataIndex: "fecha_reporte",
          key: "fecha_reporte",
          width: 100,
        });
        this.columnsExcel.push({ label: "Fecha", field: "fecha_reporte" });
      };
      if (this.actualQuery.defaultCols.codigo_registro) {
        this.columns.push({
          title: "Codigo de registro",
          dataIndex: "codigo_registro",
          key: "codigo_registro",
          width: 100,
        });
        this.columnsExcel.push({ label: "Codigo de registro", field: "codigo_registro" });
      };
      if (this.actualQuery.defaultCols.codigo_registro_uc) {
        this.columns.push({
          title: "Codigo de registro UC",
          dataIndex: "codigo_registro_uc",
          key: "codigo_registro_uc",
          width: 100,
        });
        this.columnsExcel.push({ label: "Codigo de registro UC", field: "codigo_registro_uc" });
      };
      
      if (this.actualQuery.defaultCols.formato) {
        this.columns.push({
          title: "Formato",
          dataIndex: "formato",
          key: "formato",
          width: 100,
        });
        this.columnsExcel.push({ label: "Formato", field: "formato" });
      }
      if (this.actualQuery.defaultCols.codigo_entidad) {
        if (!this.actualQuery.filter_model["codigo_entidad"]) {
          this.columns.push({
            title: "Código de la entidad",
            dataIndex: "codigo_entidad",
            key: "codigo_entidad",
            width: 200,
          });
          this.columnsExcel.push({
            label: "Código de la entidad",
            field: "codigo_entidad",
          });
        }
      }
      if (this.actualQuery.defaultCols.nombre_entidad) {
        if (!this.actualQuery.filter_model["nombre_entidad"]) {
          this.columns.push({
            title: "Entidad",
            dataIndex: "nombre_entidad",
            key: "nombre_entidad",
            width: 150,
          });
          this.columnsExcel.push({ label: "Entidad", field: "nombre_entidad" });
        }
      }
    },
    async onGetAllReport(isCsv) {
      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;
      pages_all += mod;
      const promises = [];
      let dataResults = [];
      this.json_csv = [];
      this.json_data = [];
      this.loading = true;
      for (let i = 0; i < pages_all; i++) {
        const bodyComplaint = {
          format: this.actualQuery.format.id,
          filter_model: this.actualQuery?.filter_model,
          limit: this.pageSizeDownload,
          offset: i,
          append_columns: this.actualQuery?.append_columns
        };
        promises.push(this.$api.generateReport(bodyComplaint));
      }
      Promise.allSettled(promises)
        .then((response) => {
          for (let i = 0; i < pages_all; i++) {
            const report = response[i]?.value?.data?.results;
            dataResults = [...dataResults, ...report];
          }
          if (isCsv)
            this.json_csv = dataResults.map((item) => {
              return {
                ...item,
                formato: this.actualQuery.format.format,
                fecha_reporte: formatDate(item.fecha_reporte),
              };
            });
          else
            this.json_data = dataResults.map((item) => {
              return {
                ...item,
                formato: this.actualQuery.format.format,
                fecha_reporte: formatDate(item.fecha_reporte),
              };
            });
          this.loadingReport = false;
        })
        .catch((err) => {
          this.loadingReport = false;
          this.showToast(
            "error",
            "Ocurió un error generando el reporte. Intente nuevamente."
          );
        });
        this.loading = false;
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.start();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style>
.rates .ant-table-thead > tr > th {
  font-weight: bold;
  color: #6b7280;
}
</style>
